import { HandInAnalysisModel, HandInChange, HandInModel } from "../Swagger";
import moment from "moment/moment";
import { useState } from "react";
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import AbsoluteGraph from "./AbsoluteGraph";
import { Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { useTranslation } from "react-i18next";

interface IProps {
    stats: HandInAnalysisModel;
    handIn: HandInModel;
    resolution: number;
    dataPointClicked: (e: HandInChange) => void;
}

export interface GraphData {
    count: number;
    offset: number;
    original: HandInChange[];
}

function CustomTooltip(p: { data: GraphData; }) {
    const { t } = useTranslation();

    return <Box sx={{ border: 1, padding: 1, borderRadius: '5px', boxShadow: 1, borderColor: 'gray', backgroundColor: 'white' }}>
        <Typography>{t('clickToZoom')}</Typography>
        <Typography>{t('noOfCharacters', [p.data.count])}</Typography>
    </Box>
}

function AveragedAbsoluteGraph(p: IProps) {
    const start = moment(p.handIn.start!);
    const endTimeOffset = moment(p.handIn.end!).diff(start, "minutes");
    const [zoomed, setZoomed] = useState(false);
    const [zoomData, setZoomData] = useState<HandInChange[]>([]);
    const yAxisMax = _.maxBy(p.stats.changes, e => e.totalCount)?.totalCount ?? 0;
    const { t } = useTranslation();

    const resolution = p.resolution;
    const averagedGraphData = p.stats!.changes!.reduce((p, c) => {
        const index = Math.floor(moment(c.occuredAt).diff(start, "seconds", true) / resolution) * resolution;

        p[index] = p[index] ? p[index].concat([c]) : [c];

        return p;
    }, new Array<Array<HandInChange>>()).map((e, i) => {
        const max = _.max(e.map(r => r.totalCount!));
        return { count: max, offset: i / 60, original: e };
    }).filter(e => e);

    function clickHandler(i: number) {
        const before = averagedGraphData[i - 1]?.original;
        const middle = averagedGraphData[i].original;
        const after = averagedGraphData[i + 1]?.original;
        const beforeAndMiddle = (before ? before.concat(middle) : middle)
        const data = after ? beforeAndMiddle.concat(after) : beforeAndMiddle;

        setZoomData(data);
        setZoomed(true);
    }

    function customTooltip(p: TooltipProps<ValueType, NameType>) {
        if (p.payload && p.payload[0]) return <CustomTooltip data={p.payload[0].payload} />

        return null;
    }

    return (
        <>
            <ResponsiveContainer height="99%" aspect={3}>
                {!zoomed ? <LineChart onClick={e => clickHandler(e.activeTooltipIndex ?? 0)}
                    data={averagedGraphData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" dataKey="offset" interval={'preserveStartEnd'} domain={[0, endTimeOffset]} tickFormatter={e => moment(p.handIn.start).add(e, 'minutes').format('HH:mm:ss')} />
                    <YAxis type="number" domain={[0, yAxisMax]} dataKey="count" interval={'preserveStartEnd'} >
                        <Label value={`${t('characterCount')}`} position={'insideLeft'} angle={-90} />
                    </YAxis>
                    <Tooltip content={customTooltip} />
                    <Line dataKey="count" isAnimationActive={false} />
                </LineChart> : <AbsoluteGraph dataPointClicked={p.dataPointClicked} changes={zoomData} yAxisMax={yAxisMax} />}
            </ResponsiveContainer>
            <Button onClick={() => setZoomed(false)} disabled={!zoomed}>{t('zoomOut')}</Button>
        </>
    );
}

export default AveragedAbsoluteGraph;